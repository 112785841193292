export const categoryList = [
  {
    value: "login",
    label: "登录注册相关"
  },
  {
    value: "home",
    label: "首页"
  },
  {
    value: "message",
    label: "消息中心"
  },
  {
    value: "shopping-cart",
    label: "购物车"
  },
  {
    value: "user-profile",
    label: "个人中心"
  },
  {
    value: "list",
    label: "列表页",
    children: [
      {
        value: "document-list",
        label: "常规单据列表"
      },
      {
        value: "order-list",
        label: "订单列表"
      },
      {
        value: "product-list",
        label: "商品列表"
      },
      {
        value: "other-list",
        label: "其他列表"
      }
    ]
  },
  {
    value: "detail",
    label: "详情页",
    children: [
      {
        value: "product-detail",
        label: "商品详情"
      },
      {
        value: "order-detail",
        label: "订单详情"
      },
      {
        value: "process-detail",
        label: "进度详情"
      },
      {
        value: "other-detail",
        label: "其他详情"
      }
    ]
  },
  {
    value: "form",
    label: "表单页",
    children: [
      {
        value: "order-form",
        label: "下单页面"
      },
      {
        value: "request-form",
        label: "申请页面"
      },
      {
        value: "select-form",
        label: "选项页面"
      }
    ]
  },
  {
    value: "others",
    label: "其他页面"
  }
]
