<template>
  <div
    class="demo-list"
    :style="{
      marginLeft: marginNum + 'px',
    }"
  >
    <div class="demo-list__search-bar">
      <div class="demo-list__search-bar__input">
        <cube-input v-model="demoName" placeholder="请输入名称" />
      </div>
      <div class="demo-list__search-bar__select">
        <cube-cascader
          v-model="category"
          :props="{ checkStrictly: true }"
          :options="options"
          placeholder="请选择"
        />
      </div>
    </div>
    <!-- 整体 -->
    <div class="demo-list__content">
      <!-- 第一级 -->
      <div
        v-for="item in demoList"
        :key="item.type"
        class="demo-list__content__one-level"
      >
        <div class="title-level1">{{ item.text }}(第一级标题)</div>
        <!-- 只存在第一级时 -->
        <div v-if="item.items.length" class="content">
          <div
            v-for="(items, indexs) in item.items"
            :key="indexs"
            class="content__list"
            @click="imgClick(items)"
          >
            <div class="content__list__image">
              <cube-image
                style="width: 200px"
                :src="items.previewImg"
                :preview-src-list="items.demoUrl ? [] : [items.previewImg]"
                lazy
              />
            </div>
            <div class="content__list__title">{{ items.title }}</div>
            <div class="content__list__description">
              {{ items.description || "description" }}
            </div>
          </div>
        </div>
        <!-- 存在第二级时 -->
        <div v-if="item.children && item.children.length">
          <div
            v-for="childItem in item.children"
            :key="childItem.type"
            class="demo-list__content__two-level"
          >
            <div class="title-level2">{{ childItem.text }}(第二级标题)</div>
            <div class="content">
              <div
                v-for="(childItems, childIndexs) in childItem.items"
                :key="childIndexs"
                class="content__list"
                @click="imgClick(childItems)"
              >
                <div class="content__list__image">
                  <cube-image
                    style="width: 200px"
                    :src="childItems.previewImg"
                    :preview-src-list="
                      childItems.demoUrl ? [] : [childItems.previewImg]
                    "
                    lazy
                  />
                </div>
                <div class="content__list__title">{{ childItems.title }}</div>
                <div class="content__list__description">
                  {{ childItems.description || "description" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { categoryList } from "./category-list.js"
import { pageList } from "./page-list.json"
export default {
  name: "DemoList",
  data() {
    return {
      demoName: "",
      demoCategory: "",
      category: [],
      demoList: [],
      options: [],
      marginNum: 0
    }
  },
  watch: {
    demoName() {
      this.searchDemoList(pageList, this.category)
    },
    category(newVal) {
      this.searchDemoList(pageList, newVal)
    }
  },
  created() {
    this.options = categoryList
    this.searchDemoList(pageList, this.category)
  },
  mounted() {
    const clientWidth = document.body.clientWidth
    const num = clientWidth % 250
    this.marginNum = num / 2
  },
  methods: {
    searchDemoList(list, category) {
      // list 代过滤的数组， category 带过滤的分类条件
      if (!Array.isArray(list)) return []
      const array = JSON.parse(JSON.stringify(list))
      const recursive = (array, data) => {
        const valueData = JSON.parse(JSON.stringify(data))
        return array.filter((item) => {
          // 表示选择的第一级的
          if (valueData.length === 1) {
            const conditionDemoCategory = item.type === valueData[0]
            if (conditionDemoCategory) {
              item.items = item.items.filter((demoItem) => {
                return demoItem.title.indexOf(this.demoName) > -1
              })
              return true
            }
          } else if (valueData.length === 2 && item.type === valueData[0]) {
            // 选择的第二级的
            item.children = item.children.filter((v) => {
              if (v.type === valueData[1]) {
                v.items = v.items.filter((vItem) => {
                  return vItem.title.indexOf(this.demoName) > -1
                })
                return true
              }
            })
            return true
          } else if (valueData.length === 0) {
            // 为0时，表示分类没被选择，只需比较名称继而
            // 如果存在子级，就比较子级的items中的每个元素的title内容
            if (item.children) {
              item.children = item.children.filter((v) => {
                v.items = v.items.filter((vItem) => {
                  return vItem.title.indexOf(this.demoName) > -1
                })
                return v.items.length
              })
              return item.children.length
            } else {
              // 比较的items中的每个元素的title内容
              item.items = item.items.filter((demoItem) => {
                return demoItem.title.indexOf(this.demoName) > -1
              })
              return item.items.length
            }
          }
        })
      }
      this.demoList = recursive(array, category)
    },
    imgClick(data) {
      if (!data.demoUrl) return
      // todo 后续传参看是否通过对应的list位置
      this.$router.push({
        path: "/demo/detail",
        query: { demoUrl: data.demoUrl }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
